$body-font: 'Roboto Slab', serif;
$heading-font: 'Passion One', sans-serif;
$green: #69bb36; 
$dark-green: #43683b; 
$white: #FFF;
$black:#222;



// TYPOGRAPHY /////////////////

body {
	font-family:$body-font;
}

h1,h2,h3,h4,h5,h6 {
	font-weight:normal!important;
	font-family:$body-font;
	color:#fff;
}

h2 span, h3 { 
	font-family:$heading-font; 
	color: $green; 
	text-transform:uppercase;
	line-height:.9;
}


// SECTIONS /////////////////

section {
	padding:10%;
}

#branding {
	background:$green;
	img { max-width:65%;}
	padding:7% 10%;
}

#intro {
	background:$dark-green;
}

#about {
	background:$green;

	h3 { color: $white; }
	p { color: darken($dark-green, 15%); }
	img { border-radius: 50%; }
}

#services {
	h4 {
		font-weight:800;
		color: darken($dark-green, 15%);
		background-color:$green;
		display:inline-block;
		padding:15px 25px;
		margin:0 10px 10px 0;
	}

	.accordion-title {
		font-weight:800;
		font-size: 14px;
		@include breakpoint (medium) { font-size: 21px;  }
		padding:12px 5px;
		color:$dark-green;
	}

	li.accordion-item ul li {
		list-style-type: square!important;
	}

	li.is-active { background:none; }
}

#services, #clients {
	background:$white;
	h3 { color: $dark-green; text-align: center; @include breakpoint(medium) { text-align:left; }}
}

#clients {
	padding-bottom:0;
	img { 
		padding: 10%; 
		-webkit-filter: grayscale(1);
  		filter: grayscale(1);}
	}

#testimonials {
	background:$white;

	.talk-bubble {
	  	display: inline-block;
	  	position: relative;
		height: auto;
		background-color: $dark-green;
	}

	.btm-right:before {
		content: ' ';
		position: absolute;
		width: 0;
		height: 0;
	  	left: auto;
		right: 60px;
		bottom: -40px;
		border: 20px solid;
		border-color: $dark-green $dark-green transparent transparent;
	}

	.talk-bubble p { 
		padding:10%;
		color:#fff;
		margin-bottom:0;
		@include breakpoint(medium) { font-size:21px; }
	}
	p.quoter {
		text-align: right;
		padding: 48px 24px 0;
		line-height:1.2;
		font-size:12px;
	}

	p.quoter span {
		font-size: 24px;
		font-family:$heading-font;
		text-transform:uppercase;
		color:$green;
	}
}

#footer {
	background:$dark-green;
	padding:3%;

	 a {
		font-weight:400;
		text-decoration:none;
		display:block;
		-moz-transition: all .2s ease-in-out; -o-transition: all .2s ease-in-out; -webkit-transition: all .2s ease-in-out; transition: all .2s ease-in-out;
		color: $white;
	}

	 a i {
		color:$green;
	}

	 a:hover, a:active {
		color:$green;
	}

}